import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const HANDSFREE_URL = 'https://stage.handsfreeapi.com/';

export default {
  async CreateOrder(array) {
    const token = localStorage.getItem('tokenForService');
    return axios({
      method: 'post',
      url: `${API_URL}mall-logistics/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: array,
    });
  },

  Login(username, password) {
    return axios({
      method: 'post',
      url: `${API_URL}login`,
      data: {
        username: username,
        password: password,
      },
    });
  },

  ForgotPassword(token, username) {
    return axios({
      method: 'post',
      url: `${API_URL}users/forgot-password`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        username: username,
      },
    });
  },

  GetLocation(token, branchID) {
    return axios({
      method: 'post',
      url: `${API_URL}locations/get-locations`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        branchID: branchID,
      },
    });
  },

  GetPosts(token, status) {
    return axios({
      method: 'post',
      url: `${API_URL}posts/get`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        status: status,
        withPosts: true,
        limit: 10000,
      },
    });
  },

  GetPostByOnlyBarcode(token, barcode) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/post-search-barcode`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        barcode: [barcode],
        withLogs: true,
      },
    });
  },

  GetArasKargoEcommerceCompanyList(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-aras-kargo-db-ecommerce-company-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  SetInBranch(token, postID) {
    return axios({
      method: 'post',
      url: `${API_URL}posts/set-in-branch`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        postID: [postID],
      },
    });
  },

  PostComplete(token, barcode, otpType, otp) {
    return axios({
      method: 'post',
      url: `${API_URL}posts/complete`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        barcode: barcode,
        otpType: otpType,
        otp: otp,
      },
    });
  },

  PostReturn(token, barcode) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/post-return-complete`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        barcode: barcode,
      },
    });
  },

  GetUsers(token, status) {
    return axios({
      method: 'post',
      url: `${API_URL}users/get-users`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        status: status,
      },
    });
  },

  GetUser(token, userID) {
    return axios({
      method: 'post',
      url: `${API_URL}users/get-user`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        userID: userID,
        withAuthOffices: true,
      },
    });
  },

  CreateTicket(token, title, description, type, files, username) {
    //console.log(token, title, description, type, files, username, '--------');
    return axios({
      method: 'post',
      url: `${API_URL}support/create-ticket`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        title: title,
        description: description,
        files: files,
        type: type,
        username: username,
      },
    });
  },

  GetUserByID(token, userID) {
    return axios({
      method: 'post',
      url: `${API_URL}users/get-user`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        userID: userID,
      },
    });
  },

  GetUserForTickets(token, userID) {
    return axios({
      method: 'post',
      url: `${API_URL}users/get-user`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        userID: userID,
      },
    });
  },

  GetTickets(token, status) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-tickets`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        status: status,
      },
    });
  },

  GetTicketsUser(token, createdBy) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-tickets`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        createdBy: createdBy,
      },
    });
  },

  GetTicketsStaff(token, responsibleBy) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-tickets`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        responsibleBy: responsibleBy,
        status: 3,
        operator: 'or',
      },
    });
  },

  GetTicketsManager(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-tickets`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        status: [1, 3, 4],
      },
    });
  },

  GetTicket(token, id) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-ticket`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        id: id,
      },
    });
  },

  GetTicketsByUser(token, createdBy) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-tickets`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        createdBy: createdBy,
      },
    });
  },

  CreateTicketForPostDetail(token, title, description) {
    return axios({
      method: 'post',
      url: `${API_URL}support/create-ticket`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        title: title,
        description: description,
        files: [],
        type: 'EasyNet',
      },
    });
  },

  ResendCode(token, postID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/post-recreate-deliver-password`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        postID: postID,
      },
    });
  },

  GetUserTypes(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/users-get-types`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  GetPoints(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-points`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  CreateEmployee(token, user) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/users-create-employee`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: user,
    });
  },

  UpdateProfile(token, user) {
    return axios({
      method: 'post',
      url: `${API_URL}users/update-profile`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: user,
    });
  },

  GetToken() {
    return axios({
      method: 'post',
      url: `${API_URL}get-token`,
      data: {
        key: 'wHjOKqH25WwOEeHphyGR',
        secret: '37m2LWciLhboNNFA6UkQ',
      },
    });
  },

  GetSurvey(token, surveyID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-surveys`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        surveyID
      },
    });
  },

  GetAgreement(token, handle) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/agreements-get-static`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        handle: handle,
      },
    });
  },

  PostCancel(token, postID) {
    return axios({
      method: 'post',
      url: `${API_URL}posts/cancel`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        postID: postID,
      },
    });
  },

  GetCheckRooms(token, status, startDate, endDate) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/checkroom-get`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        status: status,
        startDate: startDate,
        endDate: endDate,
      },
    });
  },

  async GetPaymentTypes() {
    const tokenResponse = await axios({
      method: 'post',
      url: `${API_URL}get-token`,
      data: {
        key: 'wHjOKqH25WwOEeHphyGR',
        secret: '37m2LWciLhboNNFA6UkQ',
      },
    });

    const token = tokenResponse.data.result.token;

    return axios({
      method: 'post',
      url: `${API_URL}flow/get-payments-types`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  SetPaymentStatus(token, postID, paymentType, invoiceID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/post-set-paid`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        postID: postID,
        paymentType: paymentType,
        invoiceID: invoiceID,
      },
    });
  },

  SendPanic(token, message) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/i-panic`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        message: message,
      },
    });
  },

  GetReport(token, queryType, startDate, endDate) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-report`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        type: 'auto',
        queryType: queryType,
        startDate: startDate,
        endDate: endDate,
      },
    });
  },

  UserSetActive(token, userID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/user-set-active`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        userID: userID,
      },
    });
  },

  UserSetPassive(token, userID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/user-set-passive`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        userID: userID,
      },
    });
  },

  GetStatistics(token, data) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-statistics`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data,
    });
  },

  GetBolParaCompanies(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-bol-para-companies`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  GetBolParaCompanyCampaigns(token, companyID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-bolpara-campaigns`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        companyID: companyID,
      },
    });
  },

  CreateBolParaCompany(token, title, logo) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/create-bol-para-company`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        title: title,
        logo: logo,
      },
    });
  },

  UpdateBolParaCompany(token, companyID, title, logo, status) {
    console.log(companyID, title, logo, status);
    return axios({
      method: 'post',
      url: `${API_URL}flow/update-bol-para-company`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        companyID: companyID,
        title: title,
        logo: logo,
        status: status,
      },
    });
  },

  CreateBolParaCampaign(
    token,
    title,
    companyID,
    description,
    images,
    start,
    end,
    type
  ) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/create-bol-para-campaign`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        title: title,
        companyID: companyID,
        description: description,
        images: images,
        start: start,
        end: end,
        type: type,
      },
    });
  },

  UpdateBolParaCampaign(
    token,
    campaignID,
    title,
    companyID,
    description,
    images,
    start,
    end,
    type
  ) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/update-bol-para-campaign`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        campaignID: campaignID,
        title: title,
        companyID: companyID,
        description: description,
        images: images,
        start: start,
        end: end,
        type: type,
      },
    });
  },

  RemoveBolParaCampaign(token, campaignID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/update-bol-para-campaign`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        campaignID: campaignID,
        status: false,
      },
    });
  },

  GetBolParaResponses(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-bol-para-responses`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },

  UpdateBolParaResponse(token, responseID, status) {
    console.log(token);
    return axios({
      method: 'post',
      url: `${API_URL}flow/update-bol-para-response-status`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        responseID: responseID,
        status: status,
      },
    });
  },

  GetCampaigns(token) {
    return axios({
      method: 'post',
      url: `${API_URL}campaigns/get-campaigns`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        audiences: ['esnaf', 'easypoint', 'personal-customers'],
        allCampaigns: true,
      },
    });
  },

  CreateCampaign(
    token,
    title,
    description,
    start,
    end,
    images,
    link,
    targetAudience
  ) {
    return axios({
      method: 'post',
      url: `${API_URL}campaigns/create`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        title: title,
        description: description,
        targetAudience: targetAudience,
        start: start,
        end: end,
        link: link,
        images: images,
      },
    });
  },
  UpdateCampaign(
    token,
    campaignID,
    title,
    description,
    start,
    end,
    images,
    link,
    targetAudience,
    status
  ) {
    return axios({
      method: 'post',
      url: `${API_URL}campaigns/update`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        campaignID: campaignID,
        title: title,
        description: description,
        targetAudience: targetAudience,
        start: start,
        end: end,
        link: link,
        images: images,
        status: status,
      },
    });
  },
  GetArasKargoEcommerceCompanyList(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/aras-kargo-get-ecommerce-company-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  AcceptArasKargoEcommerceReturn(token, accountID, orderCode, prePrintBarcode) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/aras-kargo-ecommerce-return-accept`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        accountID: accountID,
        orderCode: orderCode,
        prePrintBarcode: prePrintBarcode,
      },
    });
  },
  ArasKargoDropOffQrCheck(token, deliveryCode) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/aras-kargo-drop-off-qr-check`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        deliveryCode: deliveryCode,
      },
    });
  },
  ArasKargoDropOffAccept(token, deliveryCode, prePrintBarcode) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/aras-kargo-drop-off-accept`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        deliveryCode: deliveryCode,
        prePrintBarcode: prePrintBarcode,
      },
    });
  },
  GetArasKargoPosts(token) {
    return axios({
      method: 'post',
      url: `${API_URL}posts/get`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        status: [9],
        withPosts: false,
      },
    });
  },
  ReturnArasKargoPost(token, postID) {
    return axios({
      method: 'post',
      url: `${API_URL}posts/change-status`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: [
        {
          postID: postID,
          status: 40,
        },
      ],
    });
  },
  ResendArasKargoOTP(token, barcode) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/aras-kargo-send-otp`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        barcode: barcode,
      },
    });
  },
  ArasKargoPostComplete(token, barcode, customerNameLastname, otp) {
    return axios({
      method: 'post',
      url: `${API_URL}posts/complete`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        barcode: barcode,
        customerNameLastname: customerNameLastname,
        otpType: 'ArasKargoOTP',
        otp: otp,
      },
    });
  },
  GetDropboxListByLocations(token, locationCode, startDate, endDate) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/drop-box-list-by-location-code`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        locationCode: locationCode,
        startDate: startDate,
        endDate: endDate,
      },
    });
  },

  DeliverPostWithTC(token, postID, tckn) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/deliver-post-with-tckn`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        postID: postID,
        tckn: tckn,
      },
    });
  },

  DeliverPostWithTC(token, postID, tckn) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/deliver-post-with-tckn`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        postID: postID,
        tckn: tckn,
      },
    });
  },
  DeliverManually(token, postID) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/deliver-post-manually`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        postID: postID,
      },
    });
  },
  GetStatusList(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-post-statuses`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  SetInBranchFromOnPorter(token, barcode) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/set-in-branch-from-on-porter`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        barcode: barcode,
      },
    });
  },
  GetCities(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-cities`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  GetTowns(token, cityCode) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-towns`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        cityCode: cityCode,
      },
    });
  },
  GetRegions(token, townCode) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-districts`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        townCode: townCode,
      },
    });
  },
  GetEPAppPosts(token) {
    return axios({
      method: 'post',
      url: `${API_URL}flow/get-posts`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        dataEntranceType: "Easypoint APP API",
        limit: 20000
      },
    });
  },
};
